<template>
	<div>
  <div class="flex-container flex active-form">
		<new-form-group
      :label="
				lang.botMaker.addBot.active[languageSelected]
      "
			class="active-form"
    >
      <vx-slider :key="`slider-${formKey}`" v-model="active" @input="toggleChannel()" :disabled="readOnly"/>
    </new-form-group>
		</div>
		<new-form-group
			:label="
			field.key
			"
			v-for="(field, key) in option.fields"
			:key="`${key}-${formKey}`"
		>
			<input v-if="field.type === 'input' || field.type === 'token' || field.type === 'array'" :class="field.type === 'token' ? 'fill token-field vs-inputx vs-input--input normal' : 'fill  vs-inputx vs-input--input normal'"
						:key="`input-${key}-${formKey}`"
            v-model="values[field.key]"/>
						<p class="error-message" v-show="field.error">
						{{
							lang.botMaker.addBot.fieldError[
									languageSelected
								]
						}}
					</p>
			<!-- <vs-input v-if="field.type === 'select'" type="select" class="fill"
						:options="field.options"
            v-model="field.value"
            :danger="field.error"
            :danger-text="
              lang.botMaker.addBot.widget.error.text.empty[languageSelected]
            "/> -->
			<input type="checkbox" v-if="field.type === 'boolean'" v-model="values[field.key]" />
			<multiselect
						:key="`select-${key}-${formKey}`"
						v-if="field.type === 'select'"
            label="value"
						v-model="values[field.key]"
            :options="field.options"
            track-by="key"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder
        />
				<div v-if="field.type === 'readable'" class="flex">
					<vs-row>
						<vs-col vs-w="10" class="code-col">
							<div>
								<code class="code-tag">{{ values[field.key] }}</code>
							</div>
						</vs-col>
						<vs-col vs-w="2" class="code-col">
							<vs-button
								class="copy-button"
								size="small"
								icon="icon-clipboard"
								icon-pack="feather"
								color="primary"
								type="border"
								v-clipboard="() => values[field.key]"
								v-clipboard:success="clipboardSuccessHandler"
								v-clipboard:error="clipboardErrorHandler"
							>
								{{ lang.general.copyToClipboard.label[languageSelected] }}
							</vs-button>
						</vs-col>
					</vs-row>
				</div>
		</new-form-group>
		<div v-if="option.contactSupport" class="flex-container flex active-form">
			<span class="channel-integration-warning">
				{{ textPre }}
				<strong>{{ option.name }}</strong>
				{{ textPost }}
				<!-- <a :href="`mailto:${MAILS.support}`" target="_blank">
				{{ MAILS.support }}
			</a> -->
			</span>
		</div>
		<div>
			<vs-button v-if="active && option.fields.length > 0 && !readOnly" class="save-button" color="primary" type="filled" @click="() => toggleChannel()">
          <span>
            {{ lang.botMaker.editBot.saveBot[languageSelected] }}
          </span>
    </vs-button>
		</div>
  </div>
</template>

<script>
// import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import NewFormGroup from '../../Components/NewFormGroup.vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import store from '../../../../../../store/store'
import UUID from 'uuid/v4';
import handlebars from 'handlebars';
export default {
  name: 'ChannelForm',
  props: {
		selectedChannel: String,
		version: String,
		option: Object,
		readOnly: {
			type: Boolean,
			default: false
		}
  },
  data() {
		return {
			config: {},
			active: false,
			firstTime: true,
			values: [],
			formKey: new Date().getTime(),
			fieldsWithoutValues: null,
		}
  },
	watch: {
		option() {
			this.updateOptions();
		},
		version() {
			this.updateOptions();
		},
		integrations() {
			this.updateOptions();
		}
	},
  components: {
		NewFormGroup,
    Multiselect: () => import('vue-multiselect'),
  },
  computed: {
		...mapState(['lang', 'languageSelected']),
		...mapGetters('integrations', ['integrations', 'botChannels', 'selectedChannel', 'loaded']),
		...mapGetters('bots', [
      'bot',
    ]),
		textPre() { 
			return this.lang.botMaker.addBot.botChannels.confirm.textPre[
				this.languageSelected
			]
		},
    textPost() {
			return this.lang.botMaker.addBot.botChannels.confirm.textPost[
				this.languageSelected
			]
		}
  },
  methods: {
		...mapActions('integrations', ['CREATE_INTEGRATION', 'UPDATE_INTEGRATION', 'VERSION_CHANNEL_LOADED']),
		clipboardSuccessHandler() {
      this.$vs.notify({
        text: this.lang.general.copyToClipboard.success[this.languageSelected],
        color: 'success'
      })
    },
    clipboardErrorHandler() {
      this.$vs.notify({
        text: this.lang.general.copyToClipboard.error[this.languageSelected],
        color: 'danger'
      })
    },
		updateOptions() {
				let isThirdparty = this.option.isThirdparty ? true : false;
				const index = this.integrations.findIndex(i => i.version && (i.version === this.version || i.version._id === this.version) && i.type === this.option.type && i.thirdpartyPlatform === isThirdparty);
				this.option.fields.forEach(f => {
						this.values[f.key] = null;
				})
				if (index >= 0){
					this.active = this.integrations[index].active;
				} else {
					this.active = false;
				}
				if (index >= 0 && this.integrations[index].config) {
					this.option.fields.forEach(f => {
						if(this.integrations[index].config[f.key]) {
							if (f.type === 'select') {
								const selected = f.options.findIndex(o => o.key === this.integrations[index].config[f.key]);
								this.values[f.key] = selected;
							} else if (f.type === 'array') {
								this.values[f.key] = this.integrations[index].config[f.key].join(',');
							} else {
								this.values[f.key] = this.integrations[index].config[f.key];
							}
						} else if (f.default) {
							if (f.default === 'UUID') {
								this.values[f.key] = UUID();
							}
							if (f.default === 'webhook') {
								if (index >= 0 && this.integrations[index].config && this.integrations[index].active) {
									const template = handlebars.compile(f.webhook);
									const data = {
										backendUrl: store.state.config.konecta,
										versionId: this.version ? this.version : '',
										integrationId: this.integrations[index]._id,
										botId: this.bot.id,
										serviceId: this.bot.service.id,
									}
									this.values[f.key] = template(data);
								} else {
									this.values[f.key] = this.lang.botMaker.addBot.fieldShowWhenActive[this.languageSelected]
								}
							}
						}
					})
				} else {
					this.option.fields.forEach(f => {
						if (f.default) {
							if (f.default === 'UUID') {
								this.values[f.key] = UUID();
							}
							if (f.default === 'webhook') {
								if (index >= 0 && this.integrations[index].active) {
									const template = handlebars.compile(f.webhook);
									const data = {
										backendUrl: store.state.config.konecta,
										versionId: this.version ? this.version : '',
										integrationId: this.integrations[index]._id,
										botId: this.bot.id,
										serviceId: this.bot.service.id,
									}
									this.values[f.key] = template(data);
								} else {
									this.values[f.key] = this.lang.botMaker.addBot.fieldShowWhenActive[this.languageSelected];
								}
							}
						}
					})
				}
				this.formKey = new Date().getTime();
		},
		async createChannel() {
      try {
        this.$vs.loading({ container: this.$refs.loadingContainer, scale: 0.5 })
        const payload = {
          service: this.bot.service.id,
          type: this.option.type,
          active: this.active,
          config: {},
					version: this.version
        }
				if (this.option.isThirdparty) {
					payload.thirdpartyPlatform = true;
				} else {
					payload.thirdpartyPlatform = false;
				}
				this.option.fields.forEach(f => {
					if(f.type === 'select') {
						payload.config[f.key] = this.values[f.key] ? this.values[f.key].key : '';
					} else if (f.default != 'webhook') {
						if (f.type === 'array') {
							payload.config[f.key] = this.values[f.key] ? this.values[f.key].split(f.splitBy ? f.splitBy: ',') : [];
						} else {	
							payload.config[f.key] = this.values[f.key];
						}
					}
				});
        await this.CREATE_INTEGRATION(payload)
        this.$vs.loading.close(this.$refs.loadingContainer)
					this.$vs.notify({
					title: this.option.name,
					text: this.lang.botMaker.addBot.botChannels.messages
						.createIntegration[this.languageSelected],
					color: 'success'
				})
      } catch (error) {
        this.$vs.loading.close(this.$refs.loadingContainer)
        this.undoChannel()
      }
    },
		toggleChannel() {
      // when editing bots, channels can be created/updated
			const optionsForThisChannel = this.selectedChannel.options.map(o => o.type);
			const activeIntegration = this.integrations.find(
          i => i.version && (i.version === this.version || i.version._id === this.version) &&
					optionsForThisChannel.includes(i.type)&&
					i.active
        )
			const isThirdparty = this.option.isThirdparty ? this.option.isThirdparty : false;
      if (this.bot && this.bot.service && this.bot.service.id) {
        const integration = this.integrations.find(
          i => i.version && (i.version === this.version || i.version._id === this.version) &&
					i.type === this.option.type &&
					i.thirdpartyPlatform === isThirdparty
        )
				if (!activeIntegration || (integration && activeIntegration._id === integration._id)) {
					if (this.active && this.validateFields()) {
						this.undoChannel()
						return;
					} else {
						try {
							if (integration) {
								this.updateChannel(integration)
							} else if (this.active) {
								this.createChannel()
							}
						} catch(_) {
							this.active = false;
							this.$vs.notify({
								title: this.option.name,
								text: this.lang.botMaker.addBot.botChannels.otherActive[this.languageSelected],
								color: 'danger'
							})
						}
					}
				} else {
					this.active = false;
					this.$vs.notify({
						title: this.option.name,
						text: this.lang.botMaker.addBot.botChannels.otherActive[this.languageSelected],
						color: 'danger'
					})
				}
      }
    },
		validateFields() {
			let error = false;
			this.option.fields.forEach(f => {
				if (f.required && !this.values[f.key]) {
					error = true;
					f.error = true;
				} else {
					f.error = false;
				}
			});
			return error;
		},
    async updateChannel(channel) {
      try {
				this.$vs.loading({ container: this.$refs.loadingContainer, scale: 0.5 })
				const payload = {
					active: this.active,
					config: {}
				}
				if (this.option.isThirdparty) {
					payload.thirdpartyPlatform = true;
				} else { 
					payload.thirdpartyPlatform = false;
				}
				this.option.fields.forEach(f => {
					if(f.type === 'select') {
						payload.config[f.key] = this.values[f.key] ? this.values[f.key].key : '';
					} else if (f.default != 'webhook') {
						if (f.type === 'array') {
							payload.config[f.key] = this.values[f.key] ? this.values[f.key].split(f.splitBy ? f.splitBy: ',') : [];
						} else {	
							payload.config[f.key] = this.values[f.key];
						}
					}
				});
				await this.UPDATE_INTEGRATION({
					params: payload,
					id: channel._id
				})
				this.$vs.loading.close(this.$refs.loadingContainer)
				this.$vs.notify({
					title: this.option.name,
					text: this.lang.botMaker.addBot.botChannels.messages
						.updateIntegration[this.languageSelected],
					color: 'success'
				})
      } catch (error) {
        this.$vs.loading.close(this.$refs.loadingContainer)
        this.undoChannel()
      }
    },
		undoChannel() {
      // revert channel status update
      this.active = !this.active

      this.$vs.notify({
        title: this.lang.botMaker.errorTitle[this.languageSelected],
        text: this.lang.botMaker.addBot.botChannels.messages.errors
          .createIntegration[this.languageSelected],
        color: 'danger'
      })
    },
  },
	beforeMount() {
	// 	if (this.firstTime) {
			this.updateOptions();
	// 		this.firstTime = false;
	// 	}
	}
}
</script>

<style>
.token-field {
	-webkit-text-security: disc;
}

.error-message {
  display: block;
  height: 19px;
  color: rgba(var(--vs-danger), 1);
  font-size: 0.65rem;
}

.code-col {
	padding-top: 0px;
	padding-left: 0px;
	padding-bottom: 0px;
	padding-right: 10px;
}

.active-form {
	justify-content:center;
}

.code-tag {
	display: inline-block;
  width: 100%;
  overflow: scroll;
}
</style>
